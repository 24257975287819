/* eslint-disable no-await-in-loop */
import axios, { API_URL } from '../../services/axios';
import { DocumentSummary, Passport } from '../../types/passport';
import { AppThunk } from '../store';
import { setProfile, updateUserPassport } from '../user';
import {
  addApplicant,
  deleteAddress,
  removeApplicant,
  setLoading,
  setPassport,
  updateApplicant,
  updateApplicantAddress,
} from './preapproval.slice';

export const getPassport =
  (passportId: string) =>
  async (dispatch: any): Promise<Passport | null> => {
    try {
      const result = await axios<Passport>(
        {
          method: 'GET',
          url: `${API_URL}/mortgages/passport/${passportId}`,
        },
        dispatch,
      );
      await dispatch(setPassport(result.data));
      await dispatch(setLoading(false));
      return result.data;
    } catch (err: any) {
      return null;
    }
  };

type CreateApplicant = (passportId: string) => AppThunk;
export const createApplicant: CreateApplicant = (passportId: string) => async (dispatch) => {
  const result = await axios(
    {
      method: 'POST',
      url: `${API_URL}/mortgages/passport/${passportId}/applicant`,
    },
    dispatch,
  );

  await dispatch(addApplicant(result.data));

  return result;
};

// once a passport has been created, we patch the website user with the id.
type CreatePassport = (numberOfApplicants: number) => AppThunk<Promise<Passport>>;

export const createPassport: CreatePassport = (numberOfApplicants: number) => async (dispatch: any) => {
  const result = await axios<Passport>(
    {
      method: 'POST',
      url: `${API_URL}/mortgages/passport`,
    },
    dispatch,
  );

  await dispatch(updateUserPassport({ passportId: result.data.id }));
  await dispatch(createApplicant(result.data.id));

  if (numberOfApplicants === 2) {
    await dispatch(createApplicant(result.data.id));
  }

  await dispatch(setPassport(result.data));

  return result.data;
};

type DeleteApplicant = (passportId: string, applicantIndex: number) => AppThunk<Promise<boolean>>;
export const deleteApplicant: DeleteApplicant = (passportId: string, applicantIndex: number) => async (dispatch) => {
  await axios(
    {
      method: 'DELETE',
      url: `${API_URL}/mortgages/passport/${passportId}/applicant/${applicantIndex}`,
    },
    dispatch,
  );
  dispatch(removeApplicant({ applicantIndex }));

  return true;
};

export const patchPassport = (passportId: string, payload: any) => async (dispatch: any) => {
  // if you need to add try/catch here, know it will affect the try/catch on pre-approval steps
  const result = await axios(
    {
      method: 'PATCH',
      url: `${API_URL}/mortgages/passport/${passportId}`,
      data: payload,
    },
    dispatch,
  );

  dispatch(setPassport(result.data));

  return result;
};

export const patchApplicant = (passportId: string, applicantIndex: number, payload: any) => async (dispatch: any) => {
  // if you need to add try/catch here, know it will affect the try/catch on pre-approval steps
  const result = await axios(
    {
      method: 'PATCH',
      url: `${API_URL}/mortgages/passport/${passportId}/applicant/${applicantIndex + 1}`,
      data: payload,
    },
    dispatch,
  );

  dispatch(
    updateApplicant({
      applicantIndex,
      applicant: result.data,
    }),
  );

  return result;
};

export const createApplicantAddress = (passportId: string, applicantIndex: number) => async (dispatch: any) => {
  try {
    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/mortgages/passport/${passportId}/applicant/${applicantIndex + 1}/address`,
      },
      dispatch,
    );
    return result;
  } catch (err: any) {
    return null;
  }
};

export const patchApplicantAddress =
  (passportId: string, applicantIndex: number, addressNum: number, payload: any) => async (dispatch: any) => {
    // if you need to add try/catch here, know it will affect the try/catch on pre-approval steps
    const patchAppResult = await axios(
      {
        method: 'PATCH',
        url: `${API_URL}/mortgages/passport/${passportId}/applicant/${applicantIndex + 1}/address/${addressNum}`,
        data: payload,
      },
      dispatch,
    );

    dispatch(
      updateApplicantAddress({
        applicantIndex,
        orderIndex: addressNum,
        address: patchAppResult.data,
      }),
    );
    // await dispatch(getPassport(passportId));
    return patchAppResult;
  };

export const createAndPatchAddress =
  (passportId: string, applicantIndex: number, values: any) => async (dispatch: any) => {
    // if you need to add try/catch here, know it will affect the try/catch on pre-approval steps
    const createAddressResult = await dispatch(createApplicantAddress(passportId, applicantIndex));

    await dispatch(patchApplicantAddress(passportId, applicantIndex, createAddressResult.data.orderIndex, values));
    return createAddressResult;
  };

export const deleteApplicantAddress =
  (passportId: string, applicantIndex: number, orderIndex: number) => async (dispatch: any) => {
    try {
      dispatch(
        deleteAddress({
          applicantIndex,
          orderIndex,
        }),
      );

      await axios(
        {
          method: 'DELETE',
          url: `${API_URL}/mortgages/passport/${passportId}/applicant/${applicantIndex + 1}/address/${orderIndex}`,
        },
        dispatch,
      );

      return true;
    } catch (err: any) {
      return false;
    }
  };

type RefreshPassport = (passportId: string) => AppThunk<Promise<Boolean>>;

export const refreshPassport: RefreshPassport = (passportId) => async (dispatch, getState) => {
  const { user } = getState();

  try {
    const result = await axios<Passport>(
      {
        method: 'POST',
        url: `${API_URL}/mortgages/passport/${passportId}/refresh`,
      },
      dispatch,
    );

    await dispatch(updateUserPassport({ passportId: result.data.id }));
    dispatch(setPassport(result.data));

    // set user profile to updated profile with currentPassportId set new passport id
    if (user.profile) {
      const profile = {
        ...user.profile,
        currentPassportId: result.data.id || null,
      };

      dispatch(setProfile(profile));
    }

    return true;
  } catch (err: any) {
    return false;
  }
};

export const submitPassport = (passportId: string) => async (dispatch: any) => {
  try {
    await axios(
      {
        method: 'POST',
        url: `${API_URL}/mortgages/passport/${passportId}/submit`,
      },
      dispatch,
    );

    return true;
  } catch {
    return false;
  }
};

type UpdateDocument = (file: any, passportId: string, type: string) => AppThunk<Promise<boolean>>;
export const uploadDocument: UpdateDocument =
  (file: any, passportId: string, type: string) => async (dispatch, getState) => {
    const { preapproval } = getState();
    try {
      const formData = new FormData();

      formData.append('file', file);

      const res = await axios(
        {
          method: 'POST',
          url: `${API_URL}/mortgages/passport/${passportId}/document/type/${type}`,
          formData,
        },
        dispatch,
      );

      if (preapproval?.passport) {
        const updatedDocumentSummary = preapproval?.passport?.documentSummary?.map((doc: DocumentSummary) => {
          if (doc.type === type) {
            return {
              ...doc,
              documents: [...doc.documents, res.data],
            };
          }
          return doc;
        });

        const updatedPassport = {
          ...preapproval.passport,
          documentSummary: updatedDocumentSummary,
        };

        dispatch(setPassport(updatedPassport));
      }

      return true;
    } catch (err: any) {
      return false;
    }
  };

type DeleteDocument = (passportId: string, documentId: number) => AppThunk<Promise<boolean | null>>;
export const deleteDocument: DeleteDocument =
  (passportId: string, documentId: number) => async (dispatch, getState) => {
    const { preapproval } = getState();

    try {
      await axios<Passport>(
        {
          method: 'DELETE',
          url: `${API_URL}/mortgages/passport/${passportId}/document/${documentId}`,
          data: {},
        },
        dispatch,
      );

      if (preapproval.passport?.documentSummary) {
        const updatedDocumentSummary: DocumentSummary[] = preapproval.passport.documentSummary.map((doc) => {
          return {
            ...doc,
            documents: doc.documents.filter((document) => document.id !== documentId),
          };
        });

        const updatedPassport = {
          ...preapproval.passport,
          documentSummary: updatedDocumentSummary,
        };

        dispatch(setPassport(updatedPassport));
      }

      return true;
    } catch (err: any) {
      return null;
    }
  };
