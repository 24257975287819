/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { NextRouter } from 'next/router';
import config from '../../../../app.config.json';
import { Property } from '../types/property';
import capitalize from '../utils/capitalize';

declare global {
  const gtag: (a: string, b: any, c?: object) => void | undefined;
}

let configured = false;
let params: any = {};

/**
 * Ensure gtag is configured, can be called multiple times
 * but will only configure gtag within the scope of the
 * application, subsequent calls will be ignored.
 */
export const configureGtag = (newParams: any) => {
  if (typeof gtag !== 'undefined' && !configured) {
    gtag('js', new Date());
    gtag('config', config.googleAnalyticsKey, {
      send_page_view: false,
    });

    configured = true;
  }

  params = newParams;
};

export const trackPageView = (router: NextRouter, property?: Property) => {
  if (typeof gtag !== 'undefined') {
    let page;

    if (router.route === '/property') {
      const prefix = router.query.id?.includes('d') ? 'DDP' : 'PDP';
      page = `${prefix}`;

      if (typeof router.query.subPage === 'string') {
        if (router.query.subPage.startsWith('tour') && !!property && !!property['360Tours']) {
          const i = router.asPath.indexOf('/tour');
          const order = parseInt(router.asPath.substring(i + 5, i + 6), 10);
          const tour = property['360Tours'].find((t) => t.displayOrder === order);

          page += tour && tour.type === 'video' ? ' Video' : ' Tour';
        } else {
          page += ` ${capitalize(router.query.subPage.replace('-', ' '))}`;
        }
      } else if (router.query.photo) {
        page += ` Slideshow`;
      } else if (router.asPath.endsWith('/map')) {
        page += ` Map`;
      } else if (router.asPath.includes('/brochure/')) {
        page += ` Brochure`;
      } else if (router.asPath.includes('/enquiry')) {
        page += ` Enquiry`;
      } else if (router.asPath.includes('/report-a-problem')) {
        page += ` Report a Problem`;
      } else if (router.asPath.includes('/register-interest')) {
        page += ` Register Interest`;
      } else if (router.asPath.includes('/request-valuation')) {
        page += ` Request Valuation`;
      } else if (router.asPath.includes('/stamp-duty')) {
        page += ` Stamp Duty`;
      }
    }

    gtag('event', 'page_view', {
      page,
      page_title: document.title,
      page_location: window.location.href,
      ...params,
    });
  }
};

export const trackGaEvent = (event: string, mParams: any) => {
  if (typeof gtag !== 'undefined') {
    gtag('event', event, {
      ...params,
      ...mParams,
    });
  }
};

export const trackGaLink = (event: string, location: string, page?: string) => () => {
  if (typeof gtag !== 'undefined') {
    gtag('event', event, {
      ...params,
      page,
      location,
    });
  }
};
