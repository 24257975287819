import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import affordabilityReducer from './affordability/affordability.slice';
import appointmentReducer from './appointments/appointment.slice';
import auctionsReducer from './auctions/auctions.slice';
import bybReducer from './byb/byb.slice';
import enquiriesReducer from './enquiries/enquiries.slice';
import favouritesReducer from './favourites/favourites.slice';
import mapReducer from './map/map.slice';
import notesReducer from './notes/notes.slice';
import palsReducer from './pals/pals.slice';
import preApprovalReducer from './preapproval/preapproval.slice';
import propertiesReducer from './properties/properties.slice';
import searchReducer from './search/search.slice';
import timelineReducer from './timeline/timeline.slice';
import userReducer from './user/user.slice';
import viewingsReducer from './viewings/viewings.slice';

// persist config not used on website
// export just maintains type parity with adjacent native file
export const searchPersistConfig = {
  key: 'search',
  storage: {
    getItem: () => {},
    setItem: () => {},
    removeItem: () => {},
  },
};

const createStore = (initialState?: any) => {
  return configureStore({
    reducer: {
      notes: notesReducer,
      enquiries: enquiriesReducer,
      properties: propertiesReducer,
      map: mapReducer,
      favourites: favouritesReducer,
      search: searchReducer,
      pals: palsReducer,
      user: userReducer,
      affordability: affordabilityReducer,
      preapproval: preApprovalReducer,
      appointments: appointmentReducer,
      timeline: timelineReducer,
      byb: bybReducer,
      auctions: auctionsReducer,
      viewings: viewingsReducer,
    },
    middleware: [thunk],
    preloadedState: initialState,
  });
};

export default createStore;
