import { clearClientToken, PROFILE_IMAGE, TokenParams, updateClientToken } from '@propertypal/shared/src/utils/auth';
import axios, { API_URL } from '../../services/axios';
import { setItem } from '../../services/storage';
import { NetworkContext } from '../../types/network';
import { NotifyAgent } from '../../types/passport';
import { WebsiteUser } from '../../types/websiteUser';
import { clearEnquiries } from '../enquiries';
import { clearFavourites } from '../favourites';
import { clearNotes } from '../notes';
import { clearPals } from '../pals';
import { clearPassport } from '../preapproval';
import { clearProperties, updatePropertiesFavourites } from '../properties';
import { clearSearches } from '../search';
import { AppThunk } from '../store';
import { clearTimeline } from '../timeline';
import { setAvatarUrl, setLoggedIn, setProfile } from './user.slice';

type DefaultThunk = () => AppThunk<Promise<boolean>>;
type UpdateUser = (user: {
  firstName: string;
  lastName: string;
  email: string;
  prefix: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}) => AppThunk<Promise<[WebsiteUser | null, number | undefined]>>;
type UpdateUserPassport = (user: { passportId: string }) => AppThunk<Promise<boolean>>;
type LogoutUser = (ctx?: NetworkContext) => AppThunk;
type LoginUser = (params: TokenParams) => AppThunk<Promise<{ loggedIn: boolean; error?: string }>>;
type ForgotPassword = (email: string) => AppThunk<Promise<boolean>>;
type ResetPassword = (
  resetToken: string,
  userId: string,
  password: string,
  confirmPassword: string,
) => AppThunk<Promise<boolean>>;
type UpdateAvatar = (file: any) => AppThunk<Promise<boolean>>;
type DeleteUser = () => AppThunk<Promise<boolean>>;
type VerifyEmail = (token: string, userId: string) => AppThunk<Promise<boolean>>;

export const registerWithPropertyPal =
  (firstName: string, lastName: string, email: string, telephone: string, pass: string, token?: string) =>
  async (dispatch: any): Promise<void> => {
    const result = await axios<WebsiteUser>(
      {
        method: 'POST',
        url: `${API_URL}/website-user`,
        data: {
          firstName,
          lastName,
          email,
          telephone,
          pass,
          token,
        },
      },
      dispatch,
    );

    dispatch(setLoggedIn(true));
    setItem(PROFILE_IMAGE, result.data.avatarUrl);
    dispatch(setProfile(result.data));
  };

export const webRegisterWithPropertyPal =
  (firstName: string, lastName: string, email: string, telephone: string, pass: string, recaptcha?: string) =>
  async (dispatch: any): Promise<void> => {
    const result = await axios<WebsiteUser>(
      {
        method: 'POST',
        url: `${API_URL}/website-user/register-with-recaptcha`,
        data: {
          firstName,
          lastName,
          email,
          telephone,
          pass,
          recaptcha,
        },
      },
      dispatch,
    );

    dispatch(setLoggedIn(true));
    setItem(PROFILE_IMAGE, result.data.avatarUrl);
    dispatch(setProfile(result.data));
  };

export const updateUserPassport: UpdateUserPassport =
  ({ passportId }) =>
  async (dispatch) => {
    try {
      const result = await axios<WebsiteUser>(
        {
          method: 'PATCH',
          url: `${API_URL}/website-user`,
          data: {
            currentPassportId: passportId,
          },
        },
        dispatch,
      );

      setItem(PROFILE_IMAGE, result.data.avatarUrl);
      dispatch(setProfile(result.data));

      return true;
    } catch (err: any) {
      return false;
    }
  };

export const updateUser: UpdateUser =
  ({ firstName, lastName, email, prefix, phoneNumber, password, confirmPassword }) =>
  async (dispatch) => {
    try {
      const result = await axios<WebsiteUser>(
        {
          method: 'PATCH',
          url: `${API_URL}/website-user`,
          data: {
            firstName,
            lastName,
            email,
            telephone: `${prefix}${phoneNumber}`,
            mobile: `${prefix}${phoneNumber}`,
          },
        },
        dispatch,
      );

      setItem(PROFILE_IMAGE, result.data.avatarUrl);
      dispatch(setProfile(result.data));

      if (password && confirmPassword) {
        await axios(
          {
            method: 'POST',
            url: `${API_URL}/website-user/password`,
            data: {
              password,
              confirmPassword,
            },
          },
          dispatch,
        );
      }

      return [result.data, undefined];
    } catch (err: any) {
      return [null, err?.response?.status];
    }
  };

export const updateAvatar: UpdateAvatar = (file) => async (dispatch, getState) => {
  // get initial avatar url for when there is an error
  const avatar = getState().user?.profile?.avatarUrl;
  // eslint-disable-next-line
  const formData = new FormData();

  //  formData.append('file', JSON.stringify({ uri, name, type }));
  // @ts-ignore: Different syntax for react-native vs formData for web.
  formData.append('file', file);

  // use file.uri on app and URL.createObjectURL on web
  dispatch(setAvatarUrl(typeof document !== 'undefined' ? URL.createObjectURL(file) : file.uri));

  try {
    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/website-user/avatar`,
        formData,
      },
      dispatch,
    );

    dispatch(setAvatarUrl(result.data.avatarUrl));

    return true;
  } catch (err: any) {
    // set avatar back to its initial value
    dispatch(setAvatarUrl(avatar || ''));
    return false;
  }
};

export const deleteAvatar: DefaultThunk = () => async (dispatch) => {
  try {
    await axios(
      {
        method: 'DELETE',
        url: `${API_URL}/website-user/remove-avatar`,
      },
      dispatch,
    );

    dispatch(setAvatarUrl(''));
    return true;
  } catch (err: any) {
    // silent error
    return false;
  }
};

export const getUserDetails =
  () =>
  async (dispatch: any): Promise<WebsiteUser> => {
    const result = await axios<WebsiteUser>(
      {
        method: 'GET',
        url: `${API_URL}/website-user`,
      },
      dispatch,
    );

    setItem(PROFILE_IMAGE, result.data.avatarUrl);
    dispatch(setProfile(result.data));

    return result.data;
  };

export const loginUser: LoginUser = (params) => async (dispatch) => {
  const { token, error } = await updateClientToken(params, undefined);

  dispatch(setLoggedIn(token.loggedIn));

  if (token.loggedIn) {
    // dispatch(getUserDetails());
    dispatch(updatePropertiesFavourites());
  }

  return { loggedIn: token.loggedIn, error };
};

export const forgotPassword: ForgotPassword = (email) => async (dispatch) => {
  try {
    await axios(
      {
        method: 'POST',
        url: `${API_URL}/website-user/_ops/forgot-password`,
        data: {
          email,
        },
      },
      dispatch,
    );

    return true;
  } catch (err: any) {
    return false;
  }
};

export const resetPassword: ResetPassword = (token, userId, password, confirmPassword) => async (dispatch) => {
  try {
    await axios(
      {
        method: 'POST',
        url: `${API_URL}/website-user/password`,
        data: {
          token,
          userId,
          password,
          confirmPassword,
        },
      },
      dispatch,
    );
    return true;
  } catch (err: any) {
    return false;
  }
};

export const logoutUser: LogoutUser = (ctx?: NetworkContext) => async (dispatch) => {
  await clearClientToken(ctx);
  dispatch(clearFavourites());
  dispatch(clearTimeline());
  dispatch(clearPals());
  dispatch(clearEnquiries());
  dispatch(clearNotes());
  dispatch(clearSearches());
  dispatch(clearProperties());
  dispatch(setLoggedIn(false));
};

export const deleteUser: DeleteUser = () => async (dispatch) => {
  try {
    await axios(
      {
        method: 'DELETE',
        url: `${API_URL}/website-user`,
      },
      dispatch,
    );

    return true;
  } catch {
    return false;
  }
};

export const restoreEmail = async () => {
  try {
    await axios(
      {
        method: 'POST',
        url: `${API_URL}/website-user/_ops/restore-email`,
      },
      undefined,
    );

    return true;
  } catch {
    return false;
  }
};

export const requestEmailVerification = async () => {
  try {
    await axios(
      {
        method: 'POST',
        url: `${API_URL}/website-user/_ops/resend-verification`,
      },
      undefined,
    );

    return true;
  } catch {
    return false;
  }
};

export const verifyEmail: VerifyEmail = (token: string, userId: string) => async (dispatch, getState) => {
  try {
    const { user } = getState();

    await axios(
      {
        method: 'POST',
        url: `${API_URL}/website-user/_ops/verify-email`,
        data: {
          userId: Number(userId),
          token,
        },
      },
      dispatch,
    );

    if (user.profile) {
      dispatch(
        setProfile({
          ...user.profile,
          emailNotFound: false,
          verified: true,
        }),
      );
    }

    return true;
  } catch (err: any) {
    return false;
  }
};

export const notifyAgent = (body: NotifyAgent) => async (dispatch: any) => {
  try {
    await axios(
      {
        method: 'POST',
        url: `${API_URL}/website-user/_ops/notify-agent`,
        data: body,
      },
      dispatch,
    );

    return true;
  } catch (err: any) {
    return false;
  }
};

export const clearPassportId: DefaultThunk = () => async (dispatch, getState) => {
  const { user } = getState();

  try {
    const res = await axios<WebsiteUser>(
      {
        method: 'POST',
        url: `${API_URL}/website-user/_ops/clear-passport`,
        data: {},
      },
      dispatch,
    );

    // clear passport data from store
    await dispatch(clearPassport());

    // set user profile to updated profile with currentPassportId set to null
    if (user.profile) {
      setItem(PROFILE_IMAGE, res.data.avatarUrl);
      await dispatch(setProfile(res.data));
    }

    return true;
  } catch (err: any) {
    return false;
  }
};
