import { createSlice } from '@reduxjs/toolkit';
import { SocialFavourite } from '../../types/favourite';
import { PropertySearchResult } from '../../types/propertySearch';
import { RootState } from '../store';

export interface PropertyState {
  error?: Error;
  data?: PropertySearchResult;
  endReached: boolean;
  loading: boolean;
  page: number;
}

interface SetError {
  payload: Error;
}

interface UpdateProperties {
  payload: {
    propertyId: number;
    favourited: boolean;
  };
}

interface SetProperties {
  payload: {
    page: number;
    data: PropertySearchResult | undefined;
    loading: boolean;
  };
}

interface PushProperties {
  payload: {
    page: number;
    data: PropertySearchResult;
    loading: boolean;
  };
}

interface SetLoading {
  payload: boolean;
}

interface SetSavedSearch {
  payload: PropertySearchResult['savedSearch'];
}

interface UpdatePropertiesFavourite {
  payload: SocialFavourite[];
}

export const initialState: PropertyState = {
  endReached: false,
  loading: false,
  page: 0,
};

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setError: (state, action: SetError) => {
      state.error = action.payload;
      state.loading = false;
      state.data = undefined;
    },
    setSavedSearch: (state, action: SetSavedSearch) => {
      if (state.data) {
        state.data.savedSearch = action.payload;
      }
    },
    updatePropertyFavourite: (state, action: UpdateProperties) => {
      if (state.data) {
        state.data.results = state.data.results.map((property) => {
          if (property.id === action.payload.propertyId) {
            return {
              ...property,
              shortlisted: action.payload.favourited,
            };
          }

          return property;
        });
      }
    },
    updatePropertiesFavourite: (state, action: UpdatePropertiesFavourite) => {
      if (state.data && action.payload.length) {
        state.data.results = state.data.results.map((property) => {
          const shortlisted = action.payload.findIndex((favourite) => favourite.property.id === property.id) >= 0;

          return {
            ...property,
            shortlisted,
          };
        });
      }
    },
    setProperties: (state, action: SetProperties) => {
      state.page = action.payload.page;
      state.data = action.payload.data;
      state.loading = action.payload.loading;

      if (action.payload.data) {
        state.endReached = action.payload.page + 1 >= action.payload.data.totalPages;
      } else {
        state.endReached = false;
      }
    },
    pushProperties: (state, action: PushProperties) => {
      state.page = action.payload.page;
      state.loading = action.payload.loading;
      state.endReached = action.payload.data.page + 1 >= action.payload.data.totalPages;

      if (state.data && state.data.results) {
        state.data.results = state.data.results.concat(action.payload.data.results);
      }
    },
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    clearProperties: (state) => {
      state = { ...initialState };
      return state;
    },
    hydrateProperties: (state, action) => {
      state = { ...action.payload };
      return state;
    },
  },
});

export const {
  clearProperties,
  hydrateProperties,
  setProperties,
  setError,
  setLoading,
  setSavedSearch,
  pushProperties,
  updatePropertyFavourite,
  updatePropertiesFavourite,
} = propertiesSlice.actions;

export const selectProperties = (state: RootState) => state.properties;

export default propertiesSlice.reducer;
