import { createSlice } from '@reduxjs/toolkit';
import { Address, Applicant, Passport } from '../../types/passport';
import { RootState } from '../store';

interface State {
  currentAddress: Address | null;
  passport?: Passport;
  loading: boolean;
}

const initialState: State = {
  currentAddress: null,
  loading: false,
};

interface SetPassport {
  payload: Passport;
}

interface SetLoading {
  payload: boolean;
}

interface SetBooked {
  payload: boolean;
}

interface RemoveApplicant {
  payload: {
    applicantIndex: number;
  };
}

interface AddApplicant {
  payload: Applicant;
}

interface UpdateApplicant {
  payload: {
    applicantIndex: number;
    applicant: Applicant;
  };
}

interface UpdateApplicantAddress {
  payload: {
    applicantIndex: number;
    orderIndex: number;
    address: Address;
  };
}

interface RemoveAddress {
  payload: {
    applicantIndex: number;
    orderIndex: number;
  };
}

interface SetCurrentAddress {
  payload: Address | null;
}

export const preApprovalSlice = createSlice({
  name: 'preapproval',
  initialState,
  reducers: {
    setCurrentAddress: (state, action: SetCurrentAddress) => {
      state.currentAddress = action.payload;
    },
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    setPassport: (state, action: SetPassport) => {
      state.passport = { ...action.payload };
    },
    setBooked: (state, action: SetBooked) => {
      if (state.passport) {
        state.passport.booked = action.payload;
      }
    },
    removeApplicant: (state, action: RemoveApplicant) => {
      if (state.passport) {
        state.passport.applicants.splice(action.payload.applicantIndex, 1);
      }
    },
    addApplicant: (state, action: AddApplicant) => {
      if (state.passport) {
        state.passport.applicants.push(action.payload);
      }
    },
    updateApplicant: (state, action: UpdateApplicant) => {
      if (state.passport) {
        // held on the current applicant.
        const currentApplicant = state.passport.applicants[action.payload.applicantIndex];

        // merge neatly back into the passport state.
        state.passport.applicants[action.payload.applicantIndex] = {
          ...currentApplicant,
          ...action.payload.applicant,
        };
      }
    },
    updateApplicantAddress: (state, action: UpdateApplicantAddress) => {
      if (state.passport) {
        const currentApplicant = state.passport.applicants[action.payload.applicantIndex];

        if (currentApplicant) {
          if (currentApplicant.addresses) {
            const index = currentApplicant.addresses.findIndex((address) => {
              return address.orderIndex === action.payload.orderIndex;
            });

            if (index >= 0) {
              currentApplicant.addresses[index] = action.payload.address;
            } else {
              currentApplicant.addresses.push(action.payload.address);
            }
          } else {
            currentApplicant.addresses = [action.payload.address];
          }
        }

        state.passport.applicants[action.payload.applicantIndex] = currentApplicant;
      }
    },
    deleteAddress: (state, action: RemoveAddress) => {
      if (state.passport) {
        state.passport.applicants[action.payload.applicantIndex].addresses = state.passport.applicants[
          action.payload.applicantIndex
        ].addresses.filter((address) => {
          return address.orderIndex !== action.payload.orderIndex;
        });
      }
    },
    clearPassport: (state) => {
      state = { ...initialState, passport: undefined };

      return state;
    },
  },
});

export const {
  setCurrentAddress,
  setPassport,
  setLoading,
  removeApplicant,
  addApplicant,
  updateApplicant,
  updateApplicantAddress,
  setBooked,
  deleteAddress,
  clearPassport,
} = preApprovalSlice.actions;

export const selectPreApproval = (state: RootState) => state.preapproval;

export default preApprovalSlice.reducer;
