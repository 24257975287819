import simonCommunity from '@propertypal/shared/src/resources/logos/simon-community.png';
import { trackGaLink } from '@propertypal/shared/src/services/analytics';
import Chevron from '@propertypal/web-ui/src/icons/Chevron';
import PageWrapper from '@propertypal/web-ui/src/layout/PageWrapper';
import { TextLink } from '@propertypal/web-ui/src/typography';
import Link from 'next/link';
import React from 'react';
import { displayConsentUi } from '../../services/ads/cmp';
import CopyrightAndPolicyLinks from './copyright-and-policy-links/CopyrightAndPolicyLinks';
import { ButtonLink, FooterContainer, FooterInner, Links, LinksColumn } from './Footer.style';
import SiteMapFooter from './site-map-footer/SiteMapFooter';

export interface ListItem {
  url: string;
  label: string;
}

export interface SiteMapData {
  parent: ListItem;
  children: ListItem[];
}

interface Props {
  siteMapData: SiteMapData[] | null;
  cfIpCountry: string | null;
}

const Footer: React.FunctionComponent<Props> = (props) => {
  return (
    <FooterContainer className="hide-print">
      <PageWrapper>
        <FooterInner data-testid="footer">
          <Links>
            <LinksColumn>
              <h6>PROPERTYPAL.COM</h6>

              <ul>
                <li>
                  <Link href="/contact" prefetch={false}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link href="/find-an-agent" prefetch={false}>
                    Find an Agent
                  </Link>
                </li>
                <li>
                  <Link href="/site-map" prefetch={false}>
                    Sitemap
                  </Link>
                </li>
                <li>
                  <Link href="/careers" prefetch={false}>
                    Careers
                  </Link>
                </li>
                <li>
                  <Link href="/faq" prefetch={false}>
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link href="/mobile-apps" prefetch={false}>
                    Mobile Apps
                  </Link>
                </li>
                <li>
                  <Link
                    href="/premium"
                    prefetch={false}
                    role="link"
                    onClick={trackGaLink('premium_click', 'Footer')}
                    onKeyDown={trackGaLink('premium_click', 'Footer')}
                    tabIndex={-1}
                  >
                    Promote Your Property
                  </Link>
                </li>
                <li>
                  <button onClick={displayConsentUi} type="button">
                    Change privacy settings
                  </button>
                </li>
              </ul>
            </LinksColumn>

            {props.cfIpCountry !== 'IE' && (
              <LinksColumn>
                <h6>MORTGAGES</h6>

                <p>
                  Subject to FCA approval, PropertyPal Mortgages will be known as <b>Peden Knowles Financial</b>.
                </p>

                <p>
                  <TextLink href="/mortgages">
                    Find out more <Chevron size={10} />
                  </TextLink>
                </p>
              </LinksColumn>
            )}

            <LinksColumn>
              <h6>PROFESSIONAL</h6>

              <ul>
                <li>
                  <Link href="/products" prefetch={false}>
                    Products &amp; Services
                  </Link>
                </li>
                <li>
                  <Link href="/development-microsites" prefetch={false}>
                    Development Microsites
                  </Link>
                </li>
                <li>
                  <Link href="/estate-agent-websites" prefetch={false}>
                    Estate Agent Websites
                  </Link>
                </li>
                {props.cfIpCountry !== 'IE' && (
                  <li>
                    <Link href="/agent-advantage" prefetch={false}>
                      Agent Advantage
                    </Link>
                  </li>
                )}

                <li>
                  <Link href="/faq/agent-faq" prefetch={false}>
                    Agents FAQ
                  </Link>
                </li>

                <li>
                  <Link href="/estate-agent-information" prefetch={false} data-testid="estate-agents-login">
                    <ButtonLink>Estate Agents Login</ButtonLink>
                  </Link>
                </li>
              </ul>
            </LinksColumn>

            <LinksColumn>
              <h6>EDUCATION &amp; INSIGHTS</h6>

              <ul>
                {props.cfIpCountry !== 'IE' && (
                  <>
                    <li>
                      <Link href="/price-tracker" prefetch={false}>
                        Area Price Tracker
                      </Link>
                    </li>

                    <li>
                      <Link href="/school-finder" prefetch={false}>
                        School Finder
                      </Link>
                    </li>
                  </>
                )}

                {props.cfIpCountry === 'IE' && (
                  <li>
                    <Link href="/soldprice-checker" prefetch={false}>
                      Sold Price Checker
                    </Link>
                  </li>
                )}

                <li>
                  <a href="https://content.propertypal.com/">Insights</a>
                </li>
              </ul>
            </LinksColumn>

            <LinksColumn>
              <h6>PROUD TO PARTNER WITH</h6>

              <a href="https://simoncommunity.org/" target="_blank" rel="noreferrer">
                <img
                  src={simonCommunity.src}
                  width={130}
                  height={70}
                  loading="lazy"
                  alt="Simmon Community"
                  style={{ height: 70 }}
                />
              </a>
            </LinksColumn>
          </Links>
        </FooterInner>
      </PageWrapper>

      {props.siteMapData && props.siteMapData?.length > 0 && <SiteMapFooter siteMapData={props.siteMapData} />}

      <PageWrapper>
        <CopyrightAndPolicyLinks />
      </PageWrapper>
    </FooterContainer>
  );
};

export default Footer;
