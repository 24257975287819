import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { getAuctionDates, parseApiDate } from '../../services/dayjs';
import { ViewingDate, ViewingSearchResult } from '../../types/viewing';
import { RootState } from '../store';

export type SaleType = 1 | 2;

export interface ViewingsState {
  error?: Error;
  data?: ViewingSearchResult | null;
  dates?: ViewingDate[];
  loading: boolean;
  currentDate?: string;
  saleType: SaleType;
  term?: number | null;
}

interface SetError {
  payload: Error;
}

interface SetViewings {
  payload: {
    data?: ViewingSearchResult;
    loading: boolean;
    date?: string;
    saleType: SaleType;
    term?: number;
  };
}

interface SetLoading {
  payload: boolean;
}

export const initialState: ViewingsState = {
  loading: true,
  saleType: 1,
};

export const viewingsSlice = createSlice({
  name: 'viewings',
  initialState,
  reducers: {
    setError: (state, action: SetError) => {
      // if without parsining a stringified version of the error
      // getServerSideProps will complain about the error not being able to be serialized
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.loading = false;
      state.data = null;
    },
    setViewings: (state, action: SetViewings) => {
      state.data = action.payload.data;
      state.loading = action.payload.loading;
      state.saleType = action.payload.saleType || 1;
      state.term = action.payload.term || null;

      // parse dates to remove timezone
      const openViewingDates =
        action.payload.data?.openViewingDates && action.payload.data?.openViewingDates
          ? action.payload.data?.openViewingDates.map((o) => o.replace('+0100', '+0000'))
          : [];

      let currentDate: ViewingsState['currentDate'] = '';

      // if date is passed in request set as current date
      if (action.payload.date) currentDate = action.payload.date;
      // or else if it's the initial request, set it to the first date in openViewingDates
      else if (openViewingDates && openViewingDates.length > 0)
        currentDate = parseApiDate(openViewingDates[0]).format('YYYY-MM-DD');

      if (currentDate) state.currentDate = currentDate;

      // keeping as a constant for now, in case this exists later in mobile app with different value
      const TOTAL_DAYS = 7;

      // get the initial first date from viewingDatea return the next 6 to render in the UI
      if (openViewingDates && openViewingDates.length > 0) {
        // get the date of the first result
        const date = openViewingDates[0].replace('+0100', '+0000');
        // parse date in dayjs format to get start date
        const startDate = parseApiDate(date);
        // get end date by adding 6 days to start date
        const endDate = startDate.add(TOTAL_DAYS - 1, 'day');

        state.dates = getAuctionDates(startDate, endDate, openViewingDates);
      }
      // if no results then dates need to range from todays date until 6 days later
      else
        state.dates = getAuctionDates(
          dayjs(new Date()),
          dayjs(new Date()).add(TOTAL_DAYS - 1, 'day'),
          openViewingDates,
        );
    },
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    clearViewings: (state) => {
      state = { ...initialState };
      return state;
    },
    hydrateViewings: (state, action) => {
      state = { ...action.payload };
      return state;
    },
  },
});

export const { clearViewings, hydrateViewings, setViewings, setError, setLoading } = viewingsSlice.actions;

export const selectViewings = (state: RootState) => state.viewings;

export default viewingsSlice.reducer;
