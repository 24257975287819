import { createSlice } from '@reduxjs/toolkit';
import { Appointments } from '../../types/appointment';
import { RootState } from '../store';

export interface AppointmentsState {
  data?: Appointments;
  error?: Error | null;
  loading: boolean;
}

const initialState: AppointmentsState = {
  loading: false,
};

interface SetLoading {
  payload: boolean;
}

interface SetError {
  payload: Error | null;
}

interface SetAppointments {
  payload: Appointments;
}

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    setError: (state, action: SetError) => {
      state.error = action.payload;
    },
    setAppointments: (state, action: SetAppointments) => {
      state.data = action.payload;
    },
  },
});

export const { setLoading, setError, setAppointments } = appointmentSlice.actions;
export const selectAppointments = (state: RootState) => state.appointments.data;
export default appointmentSlice.reducer;
