export interface SerializableObject {
  [key: string]: string | string[] | number | undefined;
}

const serializeObject = (objectParams: SerializableObject) => {
  return Object.keys(objectParams)
    .filter((key) => typeof objectParams[key] !== 'undefined' && objectParams[key] !== null)
    .map((key) => {
      const param = objectParams[key];

      if (Array.isArray(param)) {
        return param
          .map((p) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(p.toString().trim())}`;
          })
          .join('&');
      }

      // @ts-ignore
      return `${encodeURIComponent(key)}=${encodeURIComponent(param.toString().trim())}`;
    })
    .join('&');
};

export default serializeObject;
