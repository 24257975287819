import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { getAuctionDates, parseApiDate } from '../../services/dayjs';
import { AuctionDate, AuctionSearchResult } from '../../types/auction';
import { RootState } from '../store';

export interface AuctionsState {
  error?: Error;
  data?: AuctionSearchResult | null;
  dates?: AuctionDate[];
  loading: boolean;
  currentDate?: string;
}

interface SetError {
  payload: Error;
}

interface SetAuctions {
  payload: {
    data?: AuctionSearchResult;
    loading: boolean;
    date?: string;
  };
}

interface SetLoading {
  payload: boolean;
}

export const initialState: AuctionsState = {
  loading: true,
};

export const auctionsSlice = createSlice({
  name: 'auctions',
  initialState,
  reducers: {
    setError: (state, action: SetError) => {
      // if without parsining a stringified version of the error
      // getServerSideProps will complain about the error not being able to be serialized
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.loading = false;
      state.data = null;
    },
    setAuctions: (state, action: SetAuctions) => {
      state.data = action.payload.data;
      state.loading = action.payload.loading;

      // parse dates to remove timezone
      const auctionDates =
        action.payload.data?.auctionDates && action.payload.data?.auctionDates.length > 0
          ? action.payload.data?.auctionDates.map((a) => a.replace('+0100', '+0000'))
          : [];

      let currentDate: AuctionsState['currentDate'] = '';

      // if date is passed in request set as current date
      if (action.payload.date) currentDate = action.payload.date;
      // or else if it's the initial request, set it to the first date in auctionDates
      else if (auctionDates && auctionDates.length > 0)
        currentDate = parseApiDate(auctionDates[0]).format('YYYY-MM-DD');

      if (currentDate) state.currentDate = currentDate;

      // keeping as a constant for now, in case this exists later in mobile app with different value
      const TOTAL_DAYS = 7;

      // get the initial first date from auctionDatea return the next 6 to render in the UI
      if (auctionDates && auctionDates.length > 0) {
        // get the date of the first result
        const date = auctionDates[0].replace('+0100', '+0000');
        // parse date in dayjs format to get start date
        const startDate = parseApiDate(date);
        // get end date by adding 6 days to start date
        const endDate = startDate.add(TOTAL_DAYS - 1, 'day');

        state.dates = getAuctionDates(startDate, endDate, auctionDates);
      }
      // if no results then dates need to range from todays date until 6 days later
      else state.dates = getAuctionDates(dayjs(new Date()), dayjs(new Date()).add(TOTAL_DAYS - 1, 'day'), auctionDates);
    },
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    clearAuctions: (state) => {
      state = { ...initialState };
      return state;
    },
    hydrateAuctions: (state, action) => {
      state = { ...action.payload };
      return state;
    },
  },
});

export const { clearAuctions, hydrateAuctions, setAuctions, setError, setLoading } = auctionsSlice.actions;

export const selectAuctions = (state: RootState) => state.auctions;

export default auctionsSlice.reducer;
