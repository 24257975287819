import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface Currency {
  item: string;
}

export interface AffordabilityState {
  numberOfApplicants: number;
  buyerType: string;
  originalIncomePerson1: string;
  incomePerson1: number;
  currencyIncomePerson1: string;
  incomePerson2: number;
  originalIncomePerson2: string;
  currencyIncomePerson2: string;
  totalFinanceAgreements: number;
  totalFinanceAgreementsPerson2: number;
  totalCreditCards: number;
  totalCreditCardsPerson2: number;
  totalFinanceOutstandingBalance: number;
  totalFinanceOutstandingBalancePerson2: number;
  totalCreditCardOutstandingBalance: number;
  totalCreditCardOutstandingBalancePerson2: number;
  totalFinanceMonthlyPayment: number;
  totalFinanceMonthlyPaymentPerson2: number;
  totalCreditCardMonthlyPayment: number;
  totalCreditCardMonthlyPaymentPerson2: number;
  numberOfDependents: number;
  childMaintenance: number;
  error?: any;
}

const initialState: AffordabilityState = {
  numberOfApplicants: 1,
  buyerType: '',
  incomePerson1: 0,
  originalIncomePerson1: '',
  originalIncomePerson2: '',
  currencyIncomePerson1: 'GBP',
  currencyIncomePerson2: 'GBP',
  incomePerson2: 0,
  totalFinanceAgreements: 0,
  totalFinanceAgreementsPerson2: 0,
  totalCreditCards: 0,
  totalCreditCardsPerson2: 0,
  totalFinanceOutstandingBalance: 0,
  totalFinanceOutstandingBalancePerson2: 0,
  totalCreditCardOutstandingBalance: 0,
  totalCreditCardOutstandingBalancePerson2: 0,
  totalFinanceMonthlyPayment: 0,
  totalFinanceMonthlyPaymentPerson2: 0,
  totalCreditCardMonthlyPayment: 0,
  totalCreditCardMonthlyPaymentPerson2: 0,
  numberOfDependents: 0,
  childMaintenance: 0,
};

interface BuyerTypeData {
  buyerType: string;
  incomePerson1: number;
  incomePerson2?: number;
  originalIncomePerson1: string;
  originalIncomePerson2?: string;
  currencyIncomePerson1: string;
  currencyIncomePerson2?: string;
  numberOfDependents?: number;
  childMaintenance?: number;
}

interface ApplicantFinancialData {
  totalFinanceAgreements: number;
  totalCreditCards: number;
  totalCreditCardOutstandingBalance: number;
  totalFinanceOutstandingBalance: number;
  totalFinanceMonthlyPayment: number;
  totalCreditCardMonthlyPayment: number;
}

interface SecondApplicantFinancialData {
  totalFinanceAgreementsPerson2: number;
  totalCreditCardsPerson2: number;
  totalCreditCardOutstandingBalancePerson2: number;
  totalFinanceOutstandingBalancePerson2: number;
  totalFinanceMonthlyPaymentPerson2: number;
  totalCreditCardMonthlyPaymentPerson2: number;
}

interface SetBuyerType {
  payload: BuyerTypeData;
}

interface SetApplicants {
  payload: number;
}

interface SetApplicantFinanceDetails {
  payload: ApplicantFinancialData;
}

interface SetSecondApplicantFinanceDetails {
  payload: SecondApplicantFinancialData;
}

export const affordabilitySlice = createSlice({
  name: 'affordability',
  initialState,
  reducers: {
    setNumberOfApplicants: (state, action: SetApplicants) => {
      state.numberOfApplicants = action.payload;
      return state;
    },
    setBuyerTypeForTwoApplicants: (state, action: SetBuyerType) => {
      state.buyerType = action.payload.buyerType;
      state.incomePerson1 = action.payload.incomePerson1;
      state.originalIncomePerson1 = action.payload.originalIncomePerson1;
      state.currencyIncomePerson1 = action.payload.currencyIncomePerson1;
      if (action.payload.incomePerson2) state.incomePerson2 = action.payload.incomePerson2;
      if (action.payload.originalIncomePerson2) state.originalIncomePerson2 = action.payload.originalIncomePerson2;
      if (action.payload.currencyIncomePerson2) state.currencyIncomePerson2 = action.payload.currencyIncomePerson2;
      state.numberOfDependents = action.payload.numberOfDependents || 0;
      state.childMaintenance = action.payload.childMaintenance || 0;
    },
    setApplicantFinancialDetails: (state, action: SetApplicantFinanceDetails) => {
      state.totalFinanceAgreements = action.payload.totalFinanceAgreements || 0;
      state.totalCreditCards = action.payload.totalCreditCards || 0;
      state.totalCreditCardOutstandingBalance = action.payload.totalCreditCardOutstandingBalance || 0;
      state.totalFinanceOutstandingBalance = action.payload.totalFinanceOutstandingBalance || 0;
      state.totalFinanceMonthlyPayment = action.payload.totalFinanceMonthlyPayment || 0;
      state.totalCreditCardMonthlyPayment = action.payload.totalCreditCardMonthlyPayment || 0;
    },
    setSecondApplicantFinancialDetails: (state, action: SetSecondApplicantFinanceDetails) => {
      state.totalFinanceAgreementsPerson2 = action.payload.totalFinanceAgreementsPerson2 || 0;
      state.totalCreditCardsPerson2 = action.payload.totalCreditCardsPerson2 || 0;
      state.totalCreditCardOutstandingBalancePerson2 = action.payload.totalCreditCardOutstandingBalancePerson2 || 0;
      state.totalFinanceOutstandingBalancePerson2 = action.payload.totalFinanceOutstandingBalancePerson2 || 0;
      state.totalFinanceMonthlyPaymentPerson2 = action.payload.totalFinanceMonthlyPaymentPerson2 || 0;
      state.totalCreditCardMonthlyPaymentPerson2 = action.payload.totalCreditCardMonthlyPaymentPerson2 || 0;
    },
    setError: (state, action) => {
      state.error = action.payload;
      return state;
    },
  },
});

export const {
  setNumberOfApplicants,
  setBuyerTypeForTwoApplicants,
  setApplicantFinancialDetails,
  setSecondApplicantFinancialDetails,
  setError,
} = affordabilitySlice.actions;

export const selectAffordability = (state: RootState) => state.affordability;

export default affordabilitySlice.reducer;
