/* eslint-disable no-undef */
import { Dispatch } from 'redux';
import config from '../../../../app.config.json';
import { setLoggedIn } from '../reducers/user';
import { API_URL } from '../services/axios';
import hmac from '../services/crypto';
import logging from '../services/logging';
import * as storage from '../services/storage';
import { getItem, removeItem, setItem } from '../services/storage.secure';
import { NetworkContext } from '../types/network';
import isReactNative from './isReactNative';
import parseJwt from './jwt';
import setEmailHashes from './liveramp';

export const DEVICE_ID = 'deviceIdv4';
export const CLIENT_TOKEN = 'client-token';
export const EMAIL_HASHES = 'email-hashes';
export const PROFILE_IMAGE = 'profile-image';
const CLIENT_SECRET = isReactNative() ? 'WAKXQjmMzcCkv4cfNttjmd24' : 'jG2jS9iU8cD1wX6iR1cM2bH9c';
const getServerToken = () => (isReactNative() ? config.serverToken : process?.env?.SERVER_TOKEN);

declare global {
  interface Window {
    ats: any;
  }
}

export interface TokenParams {
  email?: string;
  password?: string;
  aAccessToken?: string;
  aBundleId?: string;
  fbUserId?: string;
  fbAccessToken?: string;
  gAccessToken?: string;
  logout?: boolean;
  inviteId?: number;
  inviteToken?: string;
}

export interface TokenResponse {
  token: {
    authToken: string | null;
    authTokenExpires: string;
    loggedIn: boolean;
    currency: string;
  };
  error?: string;
}

export const getLoginHeader = async (requestUrl: string, ctx?: NetworkContext) => {
  const clientToken = await getItem(CLIENT_TOKEN, ctx);
  const headers: { [key: string]: string } = {};
  const serverToken = getServerToken();
  const url = requestUrl.replace(/http:\/\/localhost:\d+\/_wapi/g, config.apiUrl);
  headers['X-API-Signature'] = await hmac(url, CLIENT_SECRET);

  if (serverToken) {
    headers['X-Server-Token'] = serverToken;
  }

  if (clientToken) {
    headers['X-Client-Token'] = clientToken;
  }

  return headers;
};

export const setClientToken = async (
  clientToken: string,
  emailHashes?: string[],
  dispatch?: Dispatch,
  ctx?: NetworkContext,
) => {
  await setItem(CLIENT_TOKEN, clientToken, ctx);

  const jwt = parseJwt(clientToken);

  if (dispatch) {
    dispatch(setLoggedIn(!!jwt?.u));
  }

  if (emailHashes) {
    await storage.setItem(EMAIL_HASHES, emailHashes, ctx);
  }
};

export const getClientToken = async (ctx?: NetworkContext) => {
  const headers = await getLoginHeader(`${API_URL}/login`, ctx);

  const result = await fetch(`${API_URL}/login`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'include',
  });

  if (result.ok) {
    const data = await result.json();
    const xClientToken = result.headers.get('x-client-token');

    if (xClientToken) {
      await setClientToken(xClientToken, data.emailHashes, undefined, ctx);
    }

    return data.loggedIn;
  }

  return false;
};

export const clearClientToken = async (ctx?: NetworkContext) => {
  await removeItem(CLIENT_TOKEN, ctx);
  const headers = await getLoginHeader(`${API_URL}/logout`, ctx);

  const result = await fetch(`${API_URL}/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'include',
  });

  const data = await result.json();
  const xClientToken = result.headers.get('x-client-token');

  if (xClientToken) {
    await setClientToken(xClientToken, data.emailHashes, undefined, ctx);
  }
};

export const updateClientToken = async (tokenParams?: TokenParams, ctx?: NetworkContext): Promise<TokenResponse> => {
  const headers = await getLoginHeader(`${API_URL}/login`, ctx);

  const result = await fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'include',
    body: JSON.stringify(tokenParams),
  });

  if (result.ok) {
    const data = await result.json();
    const xClientToken = result.headers.get('x-client-token');

    if (xClientToken) {
      await setClientToken(xClientToken, data.emailHashes, undefined, ctx);
    }

    setEmailHashes(data.emailHashes);

    logging.logCrumb({
      message: 'New auth token',
      metaData: {
        expires: data.expires,
        loggedIn: data.loggedIn,
      },
    });

    return { token: data };
  }

  let errorStatus = 'LOGIN_ERROR';

  try {
    const data = await result.json();

    if (data?.invalidCredentials) errorStatus = 'INVALID_CREDENTIALS';
    if (data?.emailRequired) errorStatus = 'MISSING_EMAIL';
    if (data?.passwordRequired) errorStatus = 'MISSING_PASSWORD';
  } catch {
    // invalid json response
  }

  return {
    token: {
      authToken: null,
      authTokenExpires: '',
      loggedIn: false,
      currency: 'GBP',
    },
    error: errorStatus,
  };
};

export const getAuthHeaders = async (requestUrl: string, ctx?: NetworkContext) => {
  const headers: { [key: string]: string } = {};
  const clientToken = await getItem(CLIENT_TOKEN, ctx);
  const deviceId = await getItem(DEVICE_ID, ctx);
  const url = requestUrl.replace(/http:\/\/localhost:\d+\/_wapi/g, config.apiUrl);
  const serverToken = getServerToken();

  headers['X-API-Signature'] = await hmac(url, CLIENT_SECRET);

  if (deviceId) {
    headers['X-API-Device'] = deviceId;
    await removeItem(DEVICE_ID, ctx);
  }

  // If the user has no client token, use server token instead. The endpoint used will return a client token
  // This is not used on web because the front-end cannot read the clientToken cookie
  if (!clientToken && serverToken && (!!ctx || isReactNative())) {
    headers['X-Server-Token'] = serverToken;
  }

  // For server request, cookie must be manually set
  if (clientToken && !!ctx) {
    headers.Cookie = `client-token=${clientToken}`;
  }

  // For React Native, Server Token must always be passed with client token
  if (clientToken && isReactNative() && serverToken) {
    headers['X-Client-Token'] = clientToken;
    headers['X-Server-Token'] = serverToken;
  }

  return headers;
};
