import { createSlice } from '@reduxjs/toolkit';
import { BybResponse } from '../../types/byb';
import { RootState } from '../store';

export type BuyerType = 'firstTimeBuyer' | 'movingHome' | 'remortgage';

export interface BankSelect {
  value: string;
  label: string;
}

export interface BybState {
  buyerType: BuyerType | '';
  bankSelect: BankSelect | '';
  homeValue: number | null;
  depositValue?: number | null;
  mortgageTermYears: string;
  mortgageTermMonths: string;
  lenderMonthlyPayment?: number | null;
  outstandingBalance?: number | null;
  currentInterestRate?: number | null;
  result?: BybResponse | null;
  error?: Error | null;
}

const initialState: BybState = {
  buyerType: '',
  bankSelect: '',
  homeValue: null,
  depositValue: null,
  mortgageTermYears: '25',
  mortgageTermMonths: '0',
};

interface SetBuyerType {
  payload: BuyerType;
}

interface SetMortgageTerm {
  payload: {
    mortgageTermYears: string;
    mortgageTermMonths: string;
  };
}

interface SetBankSelect {
  payload: BankSelect;
}

interface SetResult {
  payload: BybResponse;
}

interface SetByb {
  payload: BybState;
}

export const bybSlice = createSlice({
  name: 'byb',
  initialState,
  reducers: {
    setBuyerType: (state, action: SetBuyerType) => {
      state.buyerType = action.payload;
      return state;
    },
    setBankSelection: (state, action: SetBankSelect) => {
      state.bankSelect = action.payload;
      return state;
    },
    setHomeValue: (state, action: { payload: number }) => {
      state.homeValue = action.payload;
      return state;
    },
    setDepositValue: (state, action: { payload: number }) => {
      state.depositValue = action.payload;
      return state;
    },
    setMortgageTerm: (state, action: SetMortgageTerm) => {
      state.mortgageTermYears = action.payload.mortgageTermYears;
      state.mortgageTermMonths = action.payload.mortgageTermMonths;
      return state;
    },
    setLenderMonthlyRepayment: (state, action: { payload: number }) => {
      state.lenderMonthlyPayment = action.payload;
      return state;
    },
    setOutstandingBalance: (state, action: { payload: number }) => {
      state.outstandingBalance = action.payload;
      return state;
    },
    setCurrentInterestRate: (state, action: { payload: number }) => {
      state.currentInterestRate = action.payload;
      return state;
    },
    setResult: (state, action: SetResult) => {
      state.result = action.payload;
      return state;
    },
    setByb: (state, action: SetByb) => {
      state = action.payload;
      return state;
    },
    setError: (state, action) => {
      state.error = action.payload;
      return state;
    },
    clearByb: (state) => {
      state = { ...initialState };
      return state;
    },
  },
});

export const {
  setBuyerType,
  setBankSelection,
  setHomeValue,
  setDepositValue,
  setMortgageTerm,
  setLenderMonthlyRepayment,
  setOutstandingBalance,
  setCurrentInterestRate,
  setError,
  setResult,
  setByb,
  clearByb,
} = bybSlice.actions;

export const selectByb = (state: RootState) => state.byb;

export default bybSlice.reducer;
